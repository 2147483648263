export const Logo = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 92 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.3553 22.8093C88.9804 21.5626 89.305 20.187 89.3033 18.7925H8.99291C7.50926 18.7912 6.04841 19.1574 4.74089 19.8584C3.43337 20.5593 2.31991 21.5732 1.5 22.8093H88.3553Z"
        fill="#93D500"
      />
      <path
        d="M0.947943 23.762C0.32395 25.0081 -0.000632548 26.3826 9.25459e-07 27.7762H80.311C81.7945 27.778 83.2552 27.4123 84.5628 26.7118C85.8703 26.0113 86.9839 24.9978 87.8039 23.762H0.947943Z"
        fill="#FFF200"
      />
      <path
        d="M5.99775 13.9886C5.99775 11.929 9.17276 12.7945 10.0614 12.0508H10.0809C9.95641 13.0213 9.54633 15.1031 7.42096 15.1031C6.72142 15.1031 5.99775 14.8346 5.99775 13.9886ZM7.21429 9.55652C7.35838 8.54693 8.04032 8.21844 9.15255 8.21844C10.2055 8.21844 10.6384 8.6284 10.6384 9.24824C10.6384 10.6626 8.98891 10.5694 7.79192 10.6287C5.0498 10.8353 2.49219 11.3926 2.49219 14.2584C2.49219 16.4646 3.97799 17.2565 5.97819 17.2565C7.50311 17.2565 8.55602 16.8655 9.21514 15.9158H9.25752C9.2388 16.2708 9.26508 16.6266 9.33575 16.975H12.7852C12.688 16.7031 12.6392 16.4163 12.6412 16.1277C12.6749 15.4571 12.786 14.7926 12.9724 14.1476C13.2188 13.1602 14.022 10.1874 14.022 8.7731C14.022 6.69135 12.1033 5.97049 9.13234 5.97049C6.50627 5.94051 4.45196 6.78911 3.89715 9.55587L7.21429 9.55652Z"
        fill="white"
      />
      <path
        d="M56.7938 10.6488C56.7938 12.1935 55.9704 14.5868 54.0093 14.5868C52.8547 14.5868 52.2575 13.761 52.2575 12.6491C52.2575 10.9545 52.9368 8.62637 55.042 8.62637C56.2168 8.62833 56.7938 9.43391 56.7938 10.6488ZM47.8438 16.971H51.1857L51.4999 15.4517H51.5423C52.1082 16.7702 53.0476 17.2649 54.6717 17.2649C58.3226 17.2649 60.3026 13.8223 60.3026 10.4415C60.2967 8.04825 59.1369 5.93783 56.5213 5.93783C55.9258 5.91564 55.3333 6.03182 54.7903 6.27723C54.2473 6.52263 53.7686 6.89058 53.3919 7.35216H53.3495L54.422 2.21753H50.9164L47.8438 16.971Z"
        fill="white"
      />
      <path
        d="M63.6775 13.9889C63.6775 11.9293 66.8551 12.7949 67.7411 12.0512H67.7607C67.6387 13.0217 67.2261 15.1035 65.1007 15.1035C64.3992 15.1035 63.6775 14.8343 63.6775 13.9889ZM64.894 9.55691C65.0381 8.54731 65.72 8.21882 66.8323 8.21882C67.8852 8.21882 68.3181 8.62879 68.3181 9.24862C68.3181 10.663 66.6686 10.5698 65.4716 10.6291C62.7295 10.8357 60.1719 11.3929 60.1719 14.2588C60.1719 16.465 61.6577 17.2569 63.6638 17.2569C65.1887 17.2569 66.2416 16.8659 66.9007 15.9162H66.9431C66.9279 16.2624 66.9555 16.6092 67.0253 16.9486H70.4546C70.3598 16.6836 70.311 16.4043 70.3105 16.1228C70.3471 15.4528 70.4611 14.7892 70.6501 14.1454C70.8966 13.1579 71.6998 10.1852 71.6998 8.77087C71.6998 6.68912 69.7843 5.96826 66.8101 5.96826C64.1925 5.96826 62.1297 6.79405 61.5723 9.57516L64.894 9.55691Z"
        fill="white"
      />
      <path
        d="M72.9634 6.25757H76.3588L75.9676 8.01148H76.01C76.3347 7.41387 76.8175 6.91702 77.4056 6.57527C77.9938 6.23352 78.6646 6.06003 79.3447 6.07377C79.7451 6.04401 80.1468 6.10783 80.5182 6.26017L79.8396 9.35217C79.3216 9.21006 78.7865 9.14053 78.2494 9.14556C76.334 9.14556 75.6182 10.2373 75.2668 12.0505L74.2425 16.9733H70.7422L72.9634 6.25757Z"
        fill="white"
      />
      <path
        d="M81.0103 6.25761H84.5184L84.2355 7.55593H84.2753C84.7276 7.02524 85.2951 6.60474 85.9346 6.32644C86.574 6.04814 87.2686 5.91938 87.9653 5.94997C88.372 5.91614 88.7812 5.97026 89.1652 6.10864C89.5491 6.24703 89.8987 6.46643 90.1902 6.75194C90.4817 7.03744 90.7083 7.38236 90.8546 7.76323C91.0009 8.1441 91.0635 8.552 91.038 8.95919C90.9878 9.7389 90.8627 10.512 90.6644 11.2678L89.3827 16.9733H85.8771L87.0937 11.316C87.206 10.87 87.2752 10.4143 87.3003 9.95509C87.3196 9.78 87.3001 9.60282 87.2431 9.43615C87.1861 9.26947 87.0931 9.11738 86.9707 8.9907C86.8483 8.86402 86.6994 8.76586 86.5348 8.70319C86.3701 8.64052 86.1936 8.61488 86.0179 8.62809C83.8502 8.62809 83.6057 10.6707 83.2334 12.4214L82.2829 16.9473H78.7812L81.0103 6.25761Z"
        fill="white"
      />
      <path
        d="M39.9731 3.81432e-07C38.0722 -0.000257027 36.2209 0.606651 34.6892 1.73221C33.1575 2.85777 32.0256 4.4431 31.4586 6.25698H30.3555L31.0146 3.0405H27.5084L26.8297 6.25698H22.1669L20.936 12.0506C20.655 13.5568 19.9874 14.5996 18.5049 14.5996C18.3524 14.6168 18.198 14.6002 18.0527 14.5509C17.9073 14.5016 17.7747 14.4208 17.6641 14.3144C17.5536 14.208 17.468 14.0784 17.4133 13.9351C17.3586 13.7917 17.3362 13.6381 17.3477 13.4851C17.3497 13.0549 17.398 12.6262 17.4918 12.2063L18.7116 6.26285H15.1969L13.816 12.7695C13.6817 13.3302 13.6123 13.9045 13.6094 14.481C13.6094 15.0467 13.8923 17.2836 16.804 17.2836C17.4919 17.3154 18.1764 17.1691 18.7912 16.8587C19.4059 16.5483 19.93 16.0845 20.3128 15.5121H20.3525L20.0442 16.9747H23.4056L25.1829 8.52775H26.3427L25.5193 12.4032C25.2849 13.3294 25.1464 14.2773 25.1059 15.2318C25.1059 16.8613 26.801 17.0874 28.425 17.0874C29.107 17.0874 29.7661 17.0027 30.4683 16.9629L31.0029 14.3435C30.6319 14.3663 30.2812 14.4087 29.9108 14.4087C29.3137 14.4087 28.8188 14.3689 28.8188 13.8429C28.8366 13.4124 28.8991 12.985 29.0053 12.5674L29.8528 8.52644H31.0596V8.91425C31.0597 10.6773 31.5828 12.4007 32.5627 13.8665C33.5426 15.3323 34.9353 16.4746 36.5647 17.1491C38.1941 17.8236 39.9869 17.9999 41.7165 17.6556C43.4461 17.3114 45.0347 16.4622 46.2815 15.2153C47.5283 13.9684 48.3772 12.3799 48.7209 10.6507C49.0646 8.92149 48.8876 7.12922 48.2123 5.50055C47.5371 3.87189 46.3939 2.48 44.9273 1.5009C43.4607 0.521803 41.7366 -0.000515269 39.9731 3.81432e-07ZM39.9731 14.4269C38.8839 14.4274 37.819 14.105 36.9131 13.5004C36.0073 12.8958 35.3011 12.0362 34.8839 11.0303C34.4668 10.0245 34.3574 8.91751 34.5696 7.84949C34.7819 6.78146 35.3061 5.80035 36.0762 5.03025C36.8462 4.26015 37.8274 3.73566 38.8957 3.52311C39.9639 3.31057 41.0713 3.41952 42.0776 3.8362C43.0839 4.25287 43.944 4.95854 44.549 5.86395C45.1541 6.76935 45.477 7.83383 45.4769 8.92272C45.4767 10.3821 44.8969 11.7817 43.8648 12.8139C42.8327 13.846 41.4329 14.4262 39.9731 14.4269Z"
        fill="white"
      />
    </svg>
  );
};
